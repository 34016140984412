@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  overflow-x: hidden;
}
::-webkit-scrollbar{width: 6px; height: 4px;}
::-webkit-scrollbar-track{background: #E2E8F0;}  /* #E2E8F0 */
::-webkit-scrollbar-thumb{background-color: #42464F } /* #80919E99 */
::-webkit-scrollbar-thumb:hover {
  background-color: #42464fce; /* #80919e */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-light {
  --neutralBg: #f6f7f8; /* f6f7f8 */  /* f0f2f5 */
  --primaryBg: #ffffff;
  --activeBg: #1A1B20; /* #437eca */
  --overlayBgOpaque: #1f2933cc;
  --positiveBg: #6ec2b834;
  --negativeBg: #da707434;
  --textBase: #1F232E;
  --textMuted: #1f232eaa;
  --textButtonActive: #ffffff;
  --buttonDisabled: #E0E0E0;
  --textPositive: #218B82;
  --textNegative: #da7073; /* #C9003C */
  --neutral: #E2E8F0;
  --onPrimary: #1F232E;
  --priceChartFill: #edf3edaa;
  --pillBg: #FDE68A;
}

.theme-dark {
  --neutralBg: #1A1B20;  /* #1d1b22 */  /* #1B1C1F */  /* #181818 */  /* #0C0D10 */  /* #1A1B20 */
  --primaryBg: #131419;  /* #26262e */  /* #242629 */  /* #0F0F0F */  /* #131419 */  /* #131419 */
  --activeBg: #ffffff; /* #437eca */
  --overlayBgOpaque: #5C6B77cc;
  --positiveBg: #6ec2b834; /* c56a35  3560c5 */
  --negativeBg: #da707434;
  --textBase: #FFFFFF;
  --textMuted: #ffffff80;
  --textButtonActive: #1F232E;
  --buttonDisabled: #E0E0E0;
  --textPositive: #218B82; /* 6ec2b8 */
  --textNegative: #da7073; 
  /* --red: #D64545; */
  --neutral: #42464F;
  --onPrimary: #FFFFFF;
  --priceChartFill: #2a2c35;
  --pillBg: #ba9e31;
}